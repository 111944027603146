<template>
	<div class="ppt-editor">
		<iframe id="iframeBox" @load="iframeLoaded" :src="ifSrc" style="width: 100%; height: calc(100vh - 100px)" :allowfullscreen="true"></iframe>
		<el-dialog
			width="600px"
			:title="saveTitle"
			top="30vh"
			center
			:visible.sync="saveVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				class="formItemWrap"
				:formItems="dialogItems"
				:defaultData="defaultData"
				@submit="this.submitForm"
				@cancel="saveVisible = false"
			/>
		</el-dialog>
		<!-- 地图 -->
		<el-dialog
			width="80vw"
			title="选择地图"
			top="20vh"
			center
			:visible.sync="mapVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<Map ref="personMap"></Map>
			<div slot="footer" class="dialog-footer" style="clear: both; text-align: center">
				<el-button @click="mapVisible = false">取 消</el-button>
				<el-button type="primary" @click="chooseMap">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { getUrlParams } from '@/utils/util';
import formItem from '@/views/components/components-form-item/index.vue';
import { Loading } from 'element-ui';
import Map from '../talent-assessment/list/map.vue';
export default {
	name: 'pptist',
	components: { formItem, Map },
	data() {
		return {
			saveVisible: false,
			mapVisible: false,
			saveTitle: '',
			defaultData: {},
			loading: null,
			reportId: '',
			reportInfos: [],
			reportType: '',
			planId: '',
			sendData: [],
			isLoaded: false
		};
	},
	computed: {
		ifSrc() {
			const { params } = this.$route.query;

			if (process.env.NODE_ENV === 'development') {
				return `http://localhost:8080?params=${params}`;
			}
			return `/static/ppt?params=${params}`;
		},
		dialogItems() {
			const { saveType } = this.defaultData;

			let newVerRules = [];

			if (saveType === '1') {
				newVerRules = [{ required: true, message: '请填写' }];
			}
			return [
				{
					label: '保存方式',
					key: 'saveType',
					type: 'radio',
					options: [
						{ dictId: '2', dictName: '覆盖保存' },
						{ dictId: '1', dictName: '另存为新版本' }
					],
					// span: 24,
					rules: [{ required: true, message: '请选择', trigger: 'change' }]
				},
				saveType === '2' && {
					label: '覆盖保存版本',
					key: 'oldPptId',
					type: 'select',
					options: this.reportInfos.map(({ id, version }) => ({ dictId: id, dictName: version })),
					rules: [{ required: true, message: '请选择' }]
				},
				{ label: '新版本名称', key: 'newName', type: 'input', rules: newVerRules }
			].filter((v) => v);
		},
		// 准备就绪
		canSend() {
			return this.isLoaded && this.sendData?.length > 0;
		}
	},
	watch: {
		canSend(val) {
			if (val) {
				// 传给iframe
				const iframe = document.querySelector('#iframeBox');

				setTimeout(() => {
					iframe.contentWindow.postMessage({ content: this.sendData, type: 'done' }, '*');
				}, 0);
			}
		}
	},
	async mounted() {
		window.addEventListener('message', this.getIframeData, false);
		const { params } = this.$route.query;
		const { id, pptInfos = [], type, planId } = JSON.parse(getUrlParams(params));

		this.reportId = id;
		this.reportInfos = pptInfos;
		this.reportType = type;
		this.planId = planId;
		if (type === 'edit') {
			const res = await this.checkPptLock();

			if (res.code) {
				this.isCheckError = true;
				return;
			}
			this.getPptTemplate();
			return;
		}
		this.getPptTemplate();
	},
	beforeDestroy() {
		window.removeEventListener('message', this.getIframeData);
		if (this.reportType === 'edit' && !this.isCheckError) {
			this.comeBack(true);
		}
	},

	methods: {
		iframeLoaded() {
			if (!this.getDateBefore) {
				this.loading = Loading.service({ target: '.ppt-editor' });
			}
			this.isLoaded = true;
		},
		// 保存操作
		getIframeData(e) {
			const { type, slides = '[]' } = e.data;

			if (type) {
				switch (type) {
					case 'back':
						// this.comeBack();
						this.$router.go(-1);
						break;
					case 'save':
						this.defaultData = { details: JSON.parse(slides).map((val, inx) => ({ content: JSON.stringify(val), pageNumber: inx })) };
						this.saveTitle = '保存';
						this.saveVisible = true;
						break;
					case 'insertMap':
						this.mapVisible = true;
						break;

					default:
						break;
				}
			}
		},
		comeBack(isDes) {
			this.$axios.post('/v1/internal/talent/lockPpt', { request: { id: this.reportId, lockEdit: '0' } }).then((res) => {
				if (res.code) {
					return;
				}
				if (!isDes) {
					this.$router.go(-1);
				}
			});
		},
		// 获取 报告详情 并传给iframe
		getPptTemplate() {
			// const loadding = Loading.service({ target: '.ppt-editor' });
			return this.$axios
				.post('/v1/internal/talent/getPptDetail', { request: { id: this.reportId } })
				.then((res) => {
					this.loading?.close();
					this.getDateBefore = !this.loading;
					if (res.code) {
						return this.$message.error('接口错误');
					}
					this.sendData = res.details;
				})
				.catch(() => {
					this.loading?.close();
					this.getDateBefore = !this.loading;
				});
		},
		checkPptLock() {
			return this.$axios.post('/v1/internal/talent/lockPpt', { request: { id: this.reportId, lockEdit: '1' } }).then((res) => {
				if (res.code) {
					this.$router.go(-1);
				}
				return res;
			});
		},
		// 保存报告
		submitForm(form, data) {
			form.validate((valid) => {
				if (valid) {
					const loading = Loading.service({ target: '.formItemWrap' });

					this.$axios
						.post('/v1/internal/talent/editPpt', { request: { ...data, id: this.reportId, planId: this.planId } })
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.defaultData = {};
							this.saveVisible = false;
							this.$message.success('保存成功');
							this.$router.go(-1);
						})
						.catch(() => {
							loading.close();
							this.saveVisible = false;
						});
				}
			});
		},
		// 选择地图
		chooseMap() {
			this.$refs.personMap?.getPngCanvas().then((cvs) => {
				const base64Png = cvs.toDataURL('image/png');

				// 传给iframe
				const iframe = document.querySelector('#iframeBox');

				setTimeout(() => {
					iframe.contentWindow.postMessage({ content: base64Png, type: 'map' }, '*');
					this.mapVisible = false;
				}, 0);
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.ppt-editor {
	background-color: #fff;
}
</style>
